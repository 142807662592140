<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgEnquiryStatus ref="DlgEnquiryStatus" :visible="DlgEnquiryStatusVisible" @close="DlgEnquiryStatusClose"></DlgEnquiryStatus>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>Enquiry Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark color="panel1">
                        <v-toolbar-title>Enquiry Info</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="updateEnquiry"><v-icon>save</v-icon></v-btn>
                    </v-toolbar>
                    <v-container class="pt-2 mt-3">
                        <!-- <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="(info,index) in  enquiryDetailField" :key="'enquiryIndex'+index" outlined v-model="enquiryDetail[info.fieldName]" :label="info.name" :readonly="info.readOnly"></v-text-field>
                            </v-col>
                        </v-row> -->
                        <v-row>
                            <v-col cols="12" md="12">
                                <template v-for="(info,index) in  valueList">
                                <InputField :inputName="index" :inputProp="inputProp" :content.sync="valueList[index]"  :key="'enquiryIndex'+index"></InputField>
                                <!-- {{index}} -->
                                </template>
                            </v-col>
                        </v-row>

                    </v-container>
                
                </v-card>


            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark color="panel2">
                        <v-toolbar-title>Enquiry Status</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="showDlgEnquiryStatus"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-toolbar>

                    <v-container class="pt-2 mt-3">
                        <v-card  class="mt-5">
                            <v-timeline align-top dense>
                                <template v-for="(timeLine,index) in enquiryStatusList">
                                <v-timeline-item  :key="'index' + index" small>
                                    <v-row class="pt-1">
                                        <v-col cols="12" sm="12" md="3">
                                            <div class="text-caption">{{ timeLine['rcv_date'] }}</div>
                                            <div class="text-caption">{{ timeLine['rcv_time'] }}</div>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="2">
                                            <div class="text-caption"><v-chip :color="vchipColor[timeLine['status']].color">{{ timeLine['status'] }}</v-chip></div>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <div class="text-caption"><v-icon>mdi-account</v-icon>{{ timeLine['cces_to'] }}</div>
                                            <div class="text-caption"><v-icon>mdi-phone</v-icon>{{ timeLine['cces_cell_phone'] }}</div>
                                            <div class="text-caption"><v-icon>mdi-email</v-icon>{{ timeLine['cces_email'] }}</div>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                        
                                        <strong>{{ timeLine['cces_subject'] }}</strong>
                                        <div class="text-caption">
                                            {{ timeLine['cces_description'] }}
                                        </div>
                                        </v-col>
                                    </v-row>
                                </v-timeline-item>     
                            </template>               
                            </v-timeline>
                        </v-card>
                    </v-container>
                </v-card>
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import DlgEnquiryStatus from '@/components/DlgEnquiryStatus'
import ApCallcenterService from "@/services/ApCallcenterService"
import ApParameterService from "@/services/ApParameterService"
import InputField from '@/components/InputField'
export default {
  components:{DlgMessage,DlgEnquiryStatus,InputField},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Enquiry',disabled: false,href:'#',},
              {text:'Detail',disabled: false,href:'#',},
              ],
            form:[],
            enquiryDetail:[],
            enquiryStatusDetail:[],
            enquiryStatusList:[],

            inputProp:
                {
                    "cce_ref_no":{"alias":"Ref No.","type":"text","maxlength":"50","options":[],"mandatory":true,"readonly":true},
                    "cce_priority":{"alias":"Priority","type":"text","maxlength":"50","options":[],"mandatory":true,"readonly":true},
                    "cce_channel":{"alias":"Channel","type":"select","maxlength":"50","options":[],"mandatory":true,"readonly":false},
                    "cce_type":{"alias":"Type","type":"select","maxlength":"50","options":[],"mandatory":true,"readonly":false},
                    "cce_full_name":{"alias":"Full Name","type":"text","maxlength":"50","options":[],"mandatory":true,"readonly":false},
                    "cce_email":{"alias":"Email","type":"email","maxlength":"50","options":[],"mandatory":false,"readonly":false},
                    "cce_cell_phone":{"alias":"Cell Phone","type":"number","maxlength":"50","options":[],"mandatory":false,"readonly":false},
                    "cce_category_1":{"alias":"Category 1","type":"select","maxlength":"50","options":[],"mandatory":true,"readonly":false},
                    "cce_category_2":{"alias":"Category 2","type":"select","maxlength":"50","options":[],"mandatory":false,"readonly":false},
                    "cce_category_3":{"alias":"Category 3","type":"select","maxlength":"50","options":[],"mandatory":false,"readonly":false},
                    "cce_subject":{"alias":"Subject","type":"text","maxlength":"50","options":[],"mandatory":true,"readonly":false},
                    "cce_description":{"alias":"Description","type":"textarea","maxlength":"512","options":[],"mandatory":true,"readonly":false},
                    "rcv_date":{"alias":"Enquiry Date","type":"date","maxlength":"50","options":[],"mandatory":true,"readonly":false},
                    "rcv_time":{"alias":"Enquiry Time","type":"time","maxlength":"50","options":[],"mandatory":true,"readonly":false},
                    "solved_date":{"alias":"Enquiry Closed Date:","type":"label","maxlength":"50","options":[],"mandatory":false,"readonly":true},
                    "solved_time":{"alias":"Enquiry Closed Time:","type":"label","maxlength":"50","options":[],"mandatory":false,"readonly":true},
                    "status":{"alias":"Status:","type":"v-chip","maxlength":"50","options":[],"mandatory":true,"readonly":true},"color":"",
                    "created_date":{"alias":"Created Date:","type":"label","maxlength":"50","options":[],"mandatory":false,"readonly":true,"class":"black--text"},
                    "created_by":{"alias":"Created By","type":"label","maxlength":"50","options":[],"mandatory":false,"readonly":true,"class":"black--text"},
                    "updated_date":{"alias":"Last Updated Date:","type":"label","maxlength":"50","options":[],"mandatory":false,"readonly":true,"class":"black--text"},
                    "updated_by":{"alias":"Last Updated By:","type":"label","maxlength":"50","options":[],"mandatory":false,"readonly":true,"class":"black--text"}

                },
            valueList:{
                cce_ref_no:"",cce_priority:"",cce_channel:"",cce_type:"",cce_full_name:"",cce_email:"",cce_cell_phone:"",cce_category_1:"",cce_category_2:"",cce_category_3:"",cce_subject:"",cce_description:"",rcv_date:"",rcv_time:"",status:"",solved_date:"",solved_time:"",created_date:"",created_by:"",updated_date:"",updated_by:"",

            },


            // enquiryDetailField:[
            //     {name:"Ref No.",fieldName:"cce_ref_no","readonly":true},
            //     {name:"Priority",fieldName:"cce_priority","readonly":true},
            //     {name:"Channel",fieldName:"cce_channel","readonly":true},
            //     {name:"Type",fieldName:"cce_type","readonly":true},
            //     {name:"Full Name",fieldName:"cce_full_name","readonly":true},
            //     {name:"Email",fieldName:"cce_email","readonly":true},
            //     {name:"Cell Phone",fieldName:"cce_cell_phone","readonly":true},
            //     {name:"Category 1",fieldName:"cce_category_1","readonly":true},
            //     {name:"Category 2",fieldName:"cce_category_2","readonly":true},
            //     {name:"Category 3",fieldName:"cce_category_3","readonly":true},
            //     {name:"Subject",fieldName:"cce_subject","readonly":true},
            //     {name:"Description",fieldName:"cce_description","readonly":true},
            //     {name:"Enquiry Date",fieldName:"rcv_date","readonly":true},
            //     {name:"Enquiry Time",fieldName:"rcv_time","readonly":true},
            //     {name:"Closed Date",fieldName:"solved_date","readonly":true},
            //     {name:"Closed Time",fieldName:"solved_time","readonly":true},
            //     {name:"Status",fieldName:"status","readonly":true},
            //     {name:"Sys Date",fieldName:"created_date","readonly":true},
            //     {name:"Created By",fieldName:"created_by","readonly":true},
            // ],
            routeParam:[],
            rules: {
                required: value => !!value || 'Required.',
                email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid Email address', 
                integer: value => /^[0-9]+$/.test(value) || 'Number only',
                decimal: value => /^\d{0,9}(\.\d{1,2})?$/.test(value) || 'Number with 2 decimal points',
                pinNumber: value => value.length == 6 || 'require 6 digits number',
                searchWord: value => value.length >= 3 || 'require 3 or more letters',
            },
            vchipColor:{
                "LOW":{"color":"light-green"},
                "MEDIUM":{"color":"orange"},
                "HIGH":{"color":"red"},
                "HIGHEST":{"color":"red"},
                "OPEN":{"color":"teal accent-3"},
                "IN PROGRESS":{"color":"orange"},
                "WAITING RESPONSE":{"color":"orange lighten-2"},
                "ESCALATED":{"color":"amber lighten-2"},
                "CLOSED":{"color":"green"},
                "REOPEN":{"color":"teal accent-3"},
            },
            DlgEnquiryStatusVisible:false,
            ccParameter:[],

    }
  },
  async created() {
      //console.log("input prop",this.inputProp);
      this.routeParam['cce_ref_no'] = this.$route.params.cce_ref_no;
      this.routeParam['cc_enquiry_id'] = this.$route.params.cc_enquiry_id;
      this.viewDetail();
      this.getParameter();
      
  },
  
  methods:{

    async viewDetail(){
        let request = {
            cce_ref_no:this.routeParam['cce_ref_no'],
            cc_enquiry_id:this.routeParam['cc_enquiry_id']}
        try{
            ApCallcenterService.view_enquiry(request).then((res) => {
                
                this.enquiryDetail = res.data.cceDetail;
                for (const [key, value] of Object.entries(this.enquiryDetail)) {
                    if(value == null){
                        this.enquiryDetail[key] = "";
                    }
                }                
                this.enquiryStatusList = res.data.ccesList;
                this.setValueList();



            }).catch((e)=>{
                //console.log(e.response);
                if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error','View Enquiry',e);
            })
            ;
        } catch(e) {
            //console.log(e);
            if(e)
            this.$refs.dlgMsg.showDlgBox(true,'error','View Enquiry','Unknown Error');
        }

    },
    dlgClose(){
        this.visible = false;        
    },

    async getParameter(){
            let parameter = {login_id:"",param_type:"cc_parameter"}
                ApParameterService.get_parameter(parameter).then((res) => {
                this.ccParameter = res.data.ccParam;
                // //console.log("parameter",this.ccParameter);
                this.inputProp.cce_channel['options'] = this.ccParameter.cc_enquiry_channel;
                this.inputProp.cce_type['options'] = this.ccParameter.cc_enquiry_type;
                this.inputProp.cce_category_1['options'] = this.ccParameter.cc_enquiry_category_1;
                this.inputProp.cce_category_2['options'] = this.ccParameter.cc_enquiry_category_2;
                this.inputProp.cce_category_3['options'] = this.ccParameter.cc_enquiry_category_3;
                
            }).catch((e)=>{
                if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error','View Enquiry',e.response);
            })
            ;
    },

    showDlgEnquiryStatus(){
        let enquiryStatusDetail = this.enquiryStatusList[0];
        this.$refs.DlgEnquiryStatus.dlgShow(enquiryStatusDetail);
        this.DlgEnquiryStatusVisible = true;

    },
    DlgEnquiryStatusClose(){
        this.DlgEnquiryStatusVisible = false;
        this.viewDetail();
    },
    async updateEnquiry(){
        //console.log('valueDetail',this.valueList);
        this.form = Object.assign(this.enquiryDetail,this.valueList);
        // //console.log("Value",this.valueDetail);
        // //console.log("form",this.form);
        this.form['login_id'] = "";
        try{
            ApCallcenterService.update_enquiry(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success','Update Enquiry','Success.');
            }).catch((e)=>{
                if(e.response.data.error_message)
                    this.$refs.dlgMsg.showDlgBox(true,'error','Update Enquiry',e.response.data.error_message);
                else
                    this.$refs.dlgMsg.showDlgBox(true,'error','Update Enquiry',e);
            })
            ;
        } catch(e) {
            //console.log(e);
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error','Update Enquiry','Unknown Error');
        }
    },

    setValueList(){
        // this.enquiryDetail = res.data.cceDetail;
        //console.log('value list',this.valueList);
        //console.log('enquiry detail',this.enquiryDetail['cce_ref_no']);
        if(this.enquiryDetail['status'] != 'CLOSED'){
            this.enquiryDetail['solved_date'] = "";
            this.enquiryDetail['solved_time'] = "";
        }
        
        for (const [key] of Object.entries(this.valueList)) {
            this.valueList[key] = this.enquiryDetail[key];        
        }
        this.inputProp.status.color = this.vchipColor[this.enquiryDetail['status']].color;
    }


  }
}
</script>

<template>
    <v-layout>
    <v-text-field 
        v-if="inputProp[inputName]['type']=='text'" 
        outlined 
        v-model="fieldContent" 
        :label="inputProp[inputName]['mandatory']?inputProp[inputName]['alias']+' (*)':inputProp[inputName]['alias']" 
        :readonly="inputProp[inputName]['readonly']" 
        :rules="inputProp[inputName]['mandatory']?[rules.required]:[]">
    </v-text-field>
    <v-text-field 
        v-if="inputProp[inputName]['type']=='password'" 
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        outlined 
        v-model="fieldContent" 
        :label="inputProp[inputName]['mandatory']?inputProp[inputName]['alias']+' (*)':inputProp[inputName]['alias']" 
        :readonly="inputProp[inputName]['readonly']" 
        :rules="inputProp[inputName]['mandatory']?[rules.required]:[]">
        
    </v-text-field>

    <v-text-field 
        v-if="inputProp[inputName]['type']=='email'" 
        outlined 
        v-model="fieldContent" 
        :label="inputProp[inputName]['mandatory']?inputProp[inputName]['alias']+' (*)':inputProp[inputName]['alias']" 
        :readonly="inputProp[inputName]['readonly']" 
        :rules="inputProp[inputName]['mandatory']?[rules.required,rules.email]:[rules.emailEmpty]">
    </v-text-field>

    <v-text-field 
        v-if="inputProp[inputName]['type']=='number'" 
        outlined 
        v-model="fieldContent" 
        :label="inputProp[inputName]['mandatory']?inputProp[inputName]['alias']+' (*)':inputProp[inputName]['alias']" 
        :readonly="inputProp[inputName]['readonly']" 
        :rules="inputProp[inputName]['mandatory']?[rules.required,rules.integer]:[rules.numberEmpty]">
    </v-text-field>
    <v-select v-if="inputProp[inputName]['type']=='select'"
        :label="inputProp[inputName]['mandatory']?inputProp[inputName]['alias']+' (*)':inputProp[inputName]['alias']" 
        outlined
        :items="inputProp[inputName]['options']"
        item-text="description"
        item-value="code"
        v-model="fieldContent"
        :rules="inputProp[inputName]['mandatory']?[rules.required]:[]">
    ></v-select>

    <v-textarea
        v-if="inputProp[inputName]['type']=='textarea'" 
        outlined 
        v-model="fieldContent" 
        :label="inputProp[inputName]['mandatory']?inputProp[inputName]['alias']+' (*)':inputProp[inputName]['alias']" 
        :readonly="inputProp[inputName]['readonly']" 
        :rules="inputProp[inputName]['mandatory']?[rules.required]:[]">
    </v-textarea>    

    <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290" v-if="inputProp[inputName]['type']=='date'" >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field  
                v-if="inputProp[inputName]['type']=='date'"
                outlined
                :value="fieldContent"
                :label="inputProp[inputName]['mandatory']?inputProp[inputName]['alias']+' (*)':inputProp[inputName]['alias']" 
                :readonly="inputProp[inputName]['readonly']" 
                v-bind="attrs"
                v-on="on"
                :rules="inputProp[inputName]['mandatory']?[rules.required]:[]"
            ></v-text-field>
        </template>
        <v-date-picker v-model="fieldContent" @change="dpicker.menu1 = false"></v-date-picker>
    </v-menu>

    <v-menu v-model="tpicker.menu1" :close-on-content-click="false" max-width="290"  v-if="inputProp[inputName]['type']=='time'">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-if="inputProp[inputName]['type']=='time'"
                outlined
                :value="fieldContent"
                :label="inputProp[inputName]['mandatory']?inputProp[inputName]['alias']+' (*)':inputProp[inputName]['alias']" 
                :readonly="inputProp[inputName]['readonly']" 
                v-bind="attrs"
                v-on="on"
                use-seconds
                :rules="inputProp[inputName]['mandatory']?[rules.required]:[]"
            ></v-text-field>
        </template>
        <v-time-picker v-model="fieldContent" @change="tpicker.menu1 = false"
            use-seconds
            full-width
        >

        </v-time-picker>
    </v-menu>
    <v-row  v-if="inputProp[inputName]['type']=='v-chip'" class="mb-3">
        <v-col cols="12" md="12">
            <div class="text-caption">{{inputProp[inputName]['alias']}}</div>
            <div class="text-caption"><v-chip :color="inputProp[inputName]['color']">{{ fieldContent }}</v-chip></div>
        </v-col>
    </v-row>
    <v-row  v-if="inputProp[inputName]['type']=='label'" class="mb-3">
        <v-col cols="12" md="12">
            <div class="text-caption">{{inputProp[inputName]['alias']}}</div>
            <div :class="inputProp[inputName]['class']" >{{ fieldContent }}</div>
        </v-col>
    </v-row>



    </v-layout>
</template>
    



<script>
export default {
    name: "InputField",
    props: {
        content: String,
        inputName:String,
        inputProp:Object
    },      
    components:{
    },
    computed: {
        fieldContent: {
        get() { return this.content},
        set(fieldContent) { this.$emit('update:content', fieldContent) }
        },
    },
        data() {
            return{
                showPassword:false,
                dpicker:{menu1: false,menu2: false,},
                tpicker:{menu1: false,menu2: false,},
                rules: {
                    required: value => !!value || 'Required.',
                    email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid Email address', 
                    integer: value => /^[0-9]+$/.test(value)||'Number only',
                    decimal: value => /^\d{0,9}(\.\d{1,2})?$/.test(value) || 'Number with 2 decimal points',
                    pinNumber: value => value.length == 6 || 'require 6 digits number',
                    searchWord: value => value.length >= 3 || 'require 3 or more letters',
                    numberEmpty: value => {
                            if(value.trim().length > 0)
                                return /^[0-9]+$/.test(value)||'Number only';
                            else 
                                return true;
                        },
                    emailEmpty: value => {
                            if(value.trim().length > 0)
                                return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid Email address';
                            else
                                return true;
                        },                        
                    
                },
            }
        },
        created(){
          //console.log(this.inputProp[this.inputName]['type']);
        //   //console.log(this.inputName);
        //   //console.log(this.inputProp[this.inputName]);
        },
}    

</script>
import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApCallcenterService {


    list_recent() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_callcenter/list_recent", form);

    }

    view_enquiry(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_callcenter/view_enquiry", form);

    }  
    
    search_enquiry(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_callcenter/search_enquiry", form);

    }


    insert_enquiry_status(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_callcenter/insert_enquiry_status", form);

    }

    update_enquiry(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_callcenter/update_enquiry", form);

    }

    insert_enquiry(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        // //console.log("insert form",form)
        return axios.post("ap_callcenter/insert_enquiry", form);

    }

}
    
 

export default new ApCallcenterService();